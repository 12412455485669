<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="8" lg="10" md="10" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Établissement.JURIS" />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Détail de la partie JURIS d'un établissement</div>
              </v-row>
            </v-card-title>

            <!-- le nom digital-->
            <v-card-text>
              <v-row no-gutters align="center" class="my-3">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">nom digital</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <div >{{ digitalName }}</div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le nom de la structure juridique -->
            <v-card-text>
              <v-row no-gutters align="center" class="my-3">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">structure juridique</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <div >{{ legalStructureName }}</div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- la structure juridique
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="text-uppercase font-weight-black">STRUCTURE JURIDIQUE</div>
                </v-col>
                <v-col cols="7" align-self="start">
                  <v-autocomplete
                    :items="legalStructures"
                    item-text="digitalName"
                    v-model="legalStructure"
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text> -->

            <!-- le siret -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    siret
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le siret"
                    dense
                    v-model="siret"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le code ape -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    Code APE (établissement secondaire)
                  </div>
                </v-col>
                <v-col cols="7" align-self="start">
                  <v-text-field
                    placeholder="Le code ape"
                    dense
                    v-model="ape"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- référent -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    Administrateur principal d'établissement
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le référent"
                    dense
                    v-model="establishmentMainManager"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le nom finess -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">nom finess</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le nom finess"
                    dense
                    v-model="finessName"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le numéro finess -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">numéro finess</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le numéro finess"
                    dense
                    v-model="finessNumber"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
              >
                <div class="capitalize">
                  enregistrer
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import { EstablishmentJurisService } from "@/service/etablishment/establishment_juris_service.js";
import EstablishmentsService from "@/service/etablishment/etablishment_service.js";
import { LegalStructureService } from "@/service/etablishment/legal_structure_service.js";

import { areTheSame } from "@/tools/string_tool.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "EditEstablishmentJuris",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      serviceJuris: null,
      serviceEstablishment: null,
      serviceLegalStructure: null,

      /** l'identifiant à éditer */
      entityId: null,

      /** la donnée originale */
      source: null,
      sourceEstablishment: null,

      /** le nom digital non modifiable */
      digitalName: null,
      /** le siret */
      siret: null,
      /** le code ape */
      ape: null,
      /** référent */
      establishmentMainManager: null,
      /** le nom finess */
      finessName: null,
      /** le numéro finess */
      finessNumber: null,      

      /** La structure juridique sélectionnée */
      legalStructureName: null,
      // /** la liste des structures juridique */
      // legalStructures: [],
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        let entity = await this.serviceJuris.getById(this.entityId);
        let establishment = await this.serviceEstablishment.getById(entity.establishment);
        this.digitalName = establishment.digitalName;

        // this.legalStructures = await this.serviceLegalStructure.getAll();
        // this.legalStructure = this.legalStructures.find((l) => l.id == establishment.legalStructure);
        this.legalStructureName = '-';
        if (establishment.legalStructure) {
          let legalStructure = await this.serviceLegalStructure.getById(establishment.legalStructure);
          if (legalStructure) {
            this.legalStructureName = legalStructure.digitalName;
          }
        }

        this.source = JSON.parse(JSON.stringify(entity));
        this.sourceEstablishment = JSON.parse(JSON.stringify(establishment));

        this.init();

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /**initialiser les données à partir de l'objet source */
    init() {
      this.siret = this.source.siret;
      this.ape = this.source.ape;
      this.establishmentMainManager = this.source.establishmentMainManager;        
      this.finessName = this.source.finessName;
      this.finessNumber = this.source.finessNumber;  
    },

    /** Sauvegarde de la partie Juris */
    async save() {
      let success = true;

      if (this.hasChanged) {
        try {
          let entity = JSON.parse(JSON.stringify(this.source));
          entity.siret = this.siret;
          entity.ape = this.ape;
          entity.establishmentMainManager = this.establishmentMainManager;
          entity.finessName = this.finessName;
          entity.finessNumber = this.finessNumber;

          await this.serviceJuris.update(entity);

          // /** Sauvegarde de la structure juridique */
          // if (success) {
          //   success = this.saveLegalStructure();
          // }

          /** Succès donc on reviens en arrière */
          if (success) { 
            this.disableAlertQuit();
            this.$router.go(-1);
          }
        } catch (error) {
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement de la partie Juris : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }
    },// END SAVE    

    // /** Sauvegarde de la structure juridique modifiée */
    // async saveLegalStructure() {
    //   let success = true;

    //   if (this.legalStructureHasChanged) {
    //     try {
    //       // modification de la structure juridique
    //       this.sourceEstablishment.legalStructure = this.legalStructure.id;

    //       await this.serviceEstablishment.update(this.sourceEstablishment);

    //     } catch (error) {
    //       success = false;
    //       console.error(error);
    //       this.addErrorToSnackbar(
    //         "enregistrement de la structure juridique" +
    //           (exceptions.toMessage(error) || "problème technique")
    //       );
    //     }
    //   }

    //   return success;
    // },
  },
  computed: {   
    completed() {
      if (this.siret) return true;
      if (this.ape) return true;
      if (this.establishmentMainManager) return true;
      if (this.finessName) return true;
      if (this.finessNumber) return true;

      return false;
    },
    hasChanged() {
      if (!this.source) return false;

      if (!this.completed) return false;

      let changed = false;

      if (this.entityHasChanged) {
        changed = true;
      }
      // if (this.legalStructureHasChanged) {
      //   changed = true;
      // }

      return changed;
    },
    /** Retourne true si la partie JURIS est modifiée */
    entityHasChanged() {
      let changed = false;

      if (!areTheSame(this.source.siret, this.siret)) {
        changed = true;
      }
      if (!areTheSame(this.source.ape, this.ape)) {
        changed = true;
      }
      if (!areTheSame(this.source.establishmentMainManager, this.establishmentMainManager)) {
        changed = true;
      }
      if (!areTheSame(this.source.finessName, this.finessName)) {
        changed = true;
      }
      if (!areTheSame(this.source.finessNumber, this.finessNumber)) {
        changed = true;
      }

      return changed;
    },

    // /** Retourne true si la structure juridique a changé */
    // legalStructureHasChanged() {
    //   let changed = false;

    //   if (this.sourceEstablishment.legalStructure != this.legalStructure.id) {
    //     changed = true;
    //   }

    //   return changed;
    // }
   
  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.serviceJuris = new EstablishmentJurisService(this.$api.getEstablishmentJurisApi());
    this.serviceEstablishment = new EstablishmentsService(this.$api);
    this.serviceLegalStructure = new LegalStructureService(this.$api.getLegalStructuresApi());


    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }

    this.load();
  },
};
</script>

<style>
</style>